// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import classNames from 'classnames'
import { ParallaxProvider } from 'react-scroll-parallax'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'

import CaseActionTeaser from '../CaseActionTeaser'
import CaseMainTeaser from '../CaseMainTeaser'
import FloatingCTA from '../../basic/FloatingCTA'

import baseStyles from './CasesOverview.scss'
import messages from '../CaseStudy.lang'

import { projectsList } from '../helpers'
import { StaticImage } from 'gatsby-plugin-image'

const heroBgDesktop = (
  <StaticImage src="../../../images/casestudy/herocasestudy.jpg" alt="" objectFit="cover" className="imageCover" />
)
const contactImage = (
  <StaticImage src="../../../images/casestudy/smm/Rebecca.png" alt="" objectFit="cover" className="imageCover" />
)

type Props = {
  ...StyleProps,
  data?: Object
}

const CasesOverview = ({ styles, data }: Props) => {
  const { formatMessage } = useIntl()

  return (
    <div className={styles.root}>
      <ParallaxProvider>
        <CaseMainTeaser
          title={formatMessage(messages.casesOverviewTitle)}
          description={formatMessage(messages.casesOverviewDescription)}
          modeSettings={{
            mode: 'dark',
            bgImg: heroBgDesktop,
            bgColour: 'white'
          }}
          backLink={'/'}
        />
        <CaseActionTeaser
          preTitle={projectsList.smm.name}
          title={projectsList.smm.title}
          text={projectsList.smm.description}
          img={projectsList.smm.overviewImage}
          styles={styles.firstActionTeaser}
          statement
          link={projectsList.smm.localLink}
          linkLocal
          noBackground
          icon="icon-company"
        />
        <CaseActionTeaser
          link={projectsList.lhh.localLink}
          preTitle={projectsList.lhh.name}
          title={projectsList.lhh.title}
          text={projectsList.lhh.description}
          img={projectsList.lhh.overviewImage}
          styles={classNames(styles.actionTeaser, styles.actionTeaserLightBackground)}
          statement
          linkLocal
          mirorred
          noBackground
          icon="icon-company"
        />
        <CaseActionTeaser
          link={projectsList.BCUX.localLink}
          img={projectsList.BCUX.overviewImage}
          preTitle={projectsList.BCUX.name}
          title={projectsList.BCUX.title}
          text={projectsList.BCUX.description}
          styles={classNames(styles.actionTeaser, styles.actionTeaserDarkBackground)}
          isDarkTeaser
          statement
          linkLocal
          noBackground
          linkTitle={formatMessage(messages.btnGetAdvice)}
          icon="icon-challenge"
        />
        <CaseActionTeaser
          link={projectsList.weekend.localLink}
          img={projectsList.weekend.marketingImage2}
          preTitle={projectsList.weekend.name}
          title={projectsList.weekend.title}
          text={projectsList.weekend.description}
          styles={classNames(styles.actionTeaser, styles.actionTeaserLightBackground)}
          statement
          linkLocal
          mirorred
          noBackground
          icon="icon-company"
        />
        <CaseActionTeaser
          link={projectsList.BCsales.localLink}
          img={projectsList.BCsales.overviewImage}
          preTitle={projectsList.BCsales.name}
          title={projectsList.BCsales.title}
          text={projectsList.BCsales.description}
          styles={classNames(styles.actionTeaser, styles.actionTeaserDarkBackground)}
          isDarkTeaser
          statement
          linkLocal
          noBackground
          linkTitle={formatMessage(messages.btnGetAdvice)}
          icon="icon-challenge"
        />
        <CaseActionTeaser
          link={projectsList.dertouristik.localLink}
          img={projectsList.dertouristik.overviewImage}
          preTitle={projectsList.dertouristik.name}
          title={projectsList.dertouristik.title}
          text={projectsList.dertouristik.description}
          styles={classNames(styles.actionTeaser, styles.actionTeaserLightBackground)}
          statement
          mirorred
          linkLocal
          noBackground
          icon="icon-company"
        />
        <CaseActionTeaser
          link={projectsList.plattenplaner.localLink}
          img={projectsList.plattenplaner.marketingImage2}
          preTitle={projectsList.plattenplaner.name}
          title={projectsList.plattenplaner.title}
          text={projectsList.plattenplaner.description}
          styles={styles.actionTeaser}
          statement
          linkLocal
          noBackground
          icon="icon-company"
        />
        <CaseActionTeaser
          link={projectsList.bedfinder.localLink}
          img={projectsList.bedfinder.overviewImage}
          preTitle={projectsList.bedfinder.name}
          title={projectsList.bedfinder.title}
          text={projectsList.bedfinder.description}
          styles={classNames(styles.actionTeaser, styles.actionTeaserLightBackground)}
          statement
          mirorred
          linkLocal
          noBackground
          icon="icon-company"
        />
        <CaseActionTeaser
          link={projectsList.etravel.localLink}
          img={projectsList.etravel.marketingImage2}
          preTitle={projectsList.etravel.name}
          title={projectsList.etravel.title}
          text={projectsList.etravel.description}
          styles={styles.actionTeaser}
          statement
          linkLocal
          noBackground
          icon="icon-company"
        />
        <CaseActionTeaser
          link={projectsList.aldiana.localLink}
          img={projectsList.aldiana.overviewImage}
          preTitle={projectsList.aldiana.name}
          title={projectsList.aldiana.title}
          text={projectsList.aldiana.description}
          styles={classNames(styles.actionTeaser, styles.actionTeaserLightBackground)}
          statement
          mirorred
          linkLocal
          noBackground
          icon="icon-company"
        />
      </ParallaxProvider>
      <FloatingCTA img={contactImage} />
    </div>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CasesOverview)
